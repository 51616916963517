








































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { MEarning } from '@/models/MEarning';
import { MInvoice } from '@/models/MInvoice';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinPDF } from '@/components/mixins/pdf';
import { sortAlphaNum } from '@/utils/filters/sortAlphaNumeric';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'EarningsShow',

  components: {
    BaseButtonText,
    BaseGridTable,
    BaseSelect,
    LayoutPage,
  },

  mixins: [
    mixinAlert,
    mixinModal,
    mixinPDF,
  ],

  data() {
    return {
      currentYear: String(new Date().getFullYear()),
      gridActionList: [],

      gridColumnDefinition: [
        {
          headerName: String(this.$t('earning.property.slipRange')),
          field: 'slip',
          filter: true,
          comparator: (a, b) => {
            return sortAlphaNum(a, b);
          },
        },
        {
          headerName: String(this.$t('earning.property.invoiceNumber')),
          field: 'invoiceNumber',
          filter: true,
        },
        {
          headerName: String(this.$t('earning.property.date')),
          field: 'date',
          filter: true,
        },
        {
          headerName: String(this.$t('earning.property.grossAmount')),
          field: 'grossAmount',
          filter: true,
        },
        {
          headerName: String(this.$t('earning.property.netAmount')),
          field: 'netAmount',
          filter: true,
        },
        {
          headerName: String(this.$t('earning.property.tax')),
          field: 'tax',
          filter: true,
        },
        {
          headerName: String(this.$t('earning.property.taxAmount')),
          field: 'taxAmount',
          filter: true,
        },
        {
          headerName: this.$t('outgoing.property.account'),
          field: 'account',
          filter: true,
        },
        {
          headerName: this.$t('outgoing.property.bank'),
          field: 'bank',
          filter: true,
        },
      ],
      yearList: [],
    };
  },

  computed: {
    displayedYear(): Object {
      return {
        year: this.currentYear,
      };
    },

    earningList(): Array<MEarning> {
      const earningList = [];

      this.invoiceList.forEach(invoice => {
        const data = MInvoice.getEarningData(invoice);

        earningList.push(...data);
      });

      return earningList;
    },

    invoiceList(): Array<MInvoice> {
      const invoiceList = MInvoice
        .query()
        .withAllRecursive()
        .where(invoice => {
          return invoice.DatePaid !== '' &&
                invoice.DatePaid !== null &&
                invoice.DatePaid.substr(0, 4) === this.displayedYear.year;
        }).get();

      // @ts-ignore
      return invoiceList.sort((a, b) => new Date(a.DatePaid) - new Date(b.DatePaid));
    },

    gridRowData(): Array<any> {
      let countBK = 1;
      let countCS = 1;

      return this.earningList.map(
        earning => {
          let slipNumber = 0;

          if (earning.slipRange === String(this.$t('earning.slipRange.BK'))) {
            slipNumber = countBK;
          } else {
            slipNumber = countCS;
          }

          const newEarning = {
            ...earning,
            slip: `${earning.slip}${slipNumber}`,
          };

          if (earning.slipRange === String(this.$t('earning.slipRange.BK'))) {
            countBK++;
          } else {
            countCS++;
          }

          return newEarning;
        },
      );
    },
  },

  methods: {
    exportPdf(): void {
      const sortedData = this.gridRowData.sort((a, b) => sortAlphaNum(a.slip, b.slip));

      this.exportEarningData(sortedData, this.gridColumnDefinition,
        `${this.$tc('earning.model', 2)}-${this.currentYear}`);
    },

    async loadYear(year: any): Promise<any> {
      this.currentYear = year.year;
    },
  },

  beforeMount(): void {
    this.yearList = [
      {
        year: 2020,
      },
      {
        year: 2021,
      },
      {
        year: 2022,
      },
      {
        year: 2023,
      },
      {
        year: 2024,
      },
      {
        year: 2025,
      },
      {
        year: 2026,
      },
      {
        year: 2027,
      },
      {
        year: 2028,
      },
      {
        year: 2029,
      },
      {
        year: 2030,
      },
    ];
  },
});
